.buzzer {
  height: 350px;
  width: 350px;
  border-radius: 100% !important;
  background: radial-gradient(#d01919, #3c0000) !important;
  transition: all .2s !important;
}
.buzzer:hover {
  transform: scale(1.05);
}
.buzzer.Mui-disabled {
  background: radial-gradient(#a0a0a0, #000000) !important;
}
.topBar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px;
}
.block {
    display: flex; /* required */
    flex-flow: row nowrap; /* required */
}
.block .background,
.block .foreground {
    box-sizing: border-box; /* required */
    width: 100%; /* required */
    flex: none; /* required */
}
.block .foreground {
    margin-left: -100%; /* required */
}
