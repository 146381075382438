.level-wrapper-panel {
  justify-content: center;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(15, 45px);
  grid-template-rows: repeat(auto-fill, 45px);
}
.level-box-normal {
  background-image: linear-gradient( 180deg, #fbaa80 10%, #f6c96c 100%);
  height: 45px;
  width: 45px;
  border-radius: 5px;
  position: relative;
}
.level-box-end {
  background-image: linear-gradient( 180deg, #d21f3c 10%, #ff546d 100%);
  height: 45px;
  width: 45px;
  border-radius: 5px;
  position: relative;
}
.level-box-noob {
  background-image: linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%);
  height: 45px;
  width: 45px;
  border-radius: 5px;
  position: relative;
}
.checkpoint {
  border: 5px solid yellow;
}
.level-count {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 1em;
  transform: translate(-50%,-50%);
}
.level-container-panel {
  position: relative;
}
.heart-panel{
  position: absolute;
  height: 20px;
  padding: 3px;
  right: -5px;
  top: -5px;
  border-radius: 50px;
  background-color: #ff8686;
  box-shadow: 0px 0px 5px #000;
  z-index: 99;
}
