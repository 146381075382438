.flex {
  display: flex;
}
.attendees-wrapper {
  top: 25px;
  left: 25px;
  position: absolute;
}
.right {
  left: auto !important;
  right: 25px !important;
}
.left {
  right: auto !important;
  left: 25px !important;
}
.camBorder {
  height: 100vh;
  width: 100%;
}
.rounds-wrapper {
  justify-content: center;
  width: 620px;
  margin: auto;
  display: grid;
  grid-gap: 13.5px;
  grid-template-columns: repeat(10, 50px);
  grid-template-rows: repeat(1, 50px);
  grid-auto-flow: row;
}
.highlight {
  animation: highlight 1s;
}
.highlight-slow {
  animation: highlight 1s 1s;
}
@keyframes highlight {
  0% {transform: scale(1) rotateY(0);}
  50% {transform: scale(1.3) rotateY(180deg);}
  100% {transform: scale(1) rotateY(360deg);}
}
