.level-wrapper, .game-wrapper {
  justify-content: center;
  margin: auto;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(1, 350px);
  grid-auto-rows: auto;
  grid-auto-flow: row;
}
.lives-wrapper, .counter-wrapper {
  justify-content: center;
  margin: auto;
  display: grid;
  grid-gap: 10px;
  grid-auto-columns: auto;
  grid-template-rows: repeat(1, 60px);
  grid-auto-flow: column;
}
.level-container-first {
  height: 80px;
  transition: all .4s cubic-bezier(.25,.15,0,1);
  border-radius: 15px;
  transform: scale(1.5);
  background-image: linear-gradient( 180deg, #fbaa80 10%, #f6c96c 100%);
  z-index: 900;
}
.level-container-second {
  height: 80px;
  transition: all .4s cubic-bezier(.25,.15,0,1);
  border-radius: 10px;
  transform: scale(1.25);
  background-image: linear-gradient( 180deg, #fbaa80 10%, #f6c96c 100%);
  filter: brightness(0.6) saturate(0.6);
  z-index: 800;
}
.level-container-third {
  height: 80px;
  transition: all .4s cubic-bezier(.25,.15,0,1);
  border-radius: 5px;
  transform: scale(1.05);
  background-image: linear-gradient( 180deg, #fbaa80 10%, #f6c96c 100%);
  filter: brightness(0.4) saturate(0.4);
  z-index: 700;
}
.level-container-fourth {
  border-radius: 0px;
  background-image: linear-gradient( 180deg, #fbaa80 10%, #f6c96c 100%);
  filter: brightness(0.2) saturate(0.2);
  z-index: 600;
  height: 80px;
  border: 0px;
  transition: all .4s cubic-bezier(.25,.15,0,1);
}
.level-container-fifth {
  border-radius: 0px;
  background-image: linear-gradient( 180deg, #fbaa80 10%, #f6c96c 100%);
  filter: brightness(0.2) saturate(0.2);
  z-index: 600;
  height: 0;
  overflow: hidden;
  border: 0px;
  opacity: 0;
  transition: all .4s cubic-bezier(.25,.15,0,1);
}
.level-container-last {
  background-image: linear-gradient( 180deg, #d21f3c 10%, #ff546d 100%);
}

.game-container-first {
  height: 60px;
  transform: scale(1);
  background-color: #ffffff;
  z-index: 900;
}
.game-container-second {
  height: 60px;
  transform: perspective(400px) rotateX(20deg) translateY(2px) scale(0.975);
  background-color: #ffffff;
  filter: brightness(0.6) saturate(0.6);
  z-index: 800;
}
.game-container-second-reversed {
  height: 60px;
  transform: perspective(400px) rotateX(-20deg) translateY(-2px) scale(0.975);
  background-color: #ffffff;
  filter: brightness(0.6) saturate(0.6);
  z-index: 800;
}
.game-container-third {
  height: 60px;
  transform: perspective(400px) rotateX(50deg) translateY(24px) scale(0.865);
  background-color: #ffffff;
  filter: brightness(0.4) saturate(0.4);
  z-index: 700;
}
.game-container-third-reversed {
  height: 60px;
  transform: perspective(400px) rotateX(-50deg) translateY(-24px) scale(0.865);
  background-color: #ffffff;
  filter: brightness(0.4) saturate(0.4);
  z-index: 700;
}
.game-container-fourth {
  background-color: #ffffff;
  filter: brightness(0.2) saturate(0.2);
  transform: perspective(400px) rotateX(80deg) translateY(171px) scale(0.47);
  z-index: 600;
  height: 60px;
  border: 0px;
}
.game-container-fourth-reversed {
  background-color: #ffffff;
  filter: brightness(0.2) saturate(0.2);
  transform: perspective(400px) rotateX(-80deg) translateY(-171px) scale(0.47);
  z-index: 600;
  height: 60px;
  border: 0px;
}
.game-container-fifth {
  border-radius: 0px;
  background-color: #ffffff;
  filter: brightness(0.2) saturate(0.2);
  z-index: 600;
  height: 0;
  display: none;
  overflow: hidden;
  opacity: 0;
  transform: perspective(0px) rotateX(90deg) translateY(-171px) scale(0);
}
.arrow-left {
  width: 0;
  height: 0;
  float: left;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #ffc600;
}
.arrow-right {
  width: 0;
  height: 0;
  float: right;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #ffc600;
}

.level-container-safe {
  border: 5px solid yellow;
}
.game-value {
  font-size: 20px !important;
  color: #000000 !important;
}
.level-value, .game-value {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 1em;
  transform: translate(-50%,-50%);
}
.level-value {
  font-size: 1.8rem !important;
  width: 100%;
}
.noob-level {
  background-image: linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%) !important;
}
.joker-container {
  position: relative;
  margin-left: 25px;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-image: linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%);
}
.joker-container-used {
  position: relative;
  margin-left: 25px;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-image: linear-gradient( 135deg, #7d7d7d 10%, #3c3c3c 100%);
}
.joker {
  height: 40px;
  margin-top: 11px;
  position: absolute;
  left: 11px;
  z-index: 50;
}

.lives-container {
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-image: linear-gradient( 135deg, #FF9D6C 10%, #BB4E75 100%);
}
.lives-container-lost {
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-image: linear-gradient( 135deg, #7d7d7d 10%, #3c3c3c 100%);
}

.counter-container {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-image: linear-gradient( 135deg, #7d7d7d 10%, #3c3c3c 100%);
}
.counter-container-elapsed {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-image: linear-gradient( 135deg, #81FBB8 10%, #28C76F 100%);
}
.heart {
  height: 40px;
  margin-top: 12px;
  position: absolute;
  left: 11px;
  z-index: 50;
}
.cross {
  height: 70px;
  z-index: 90;
  position: absolute;
  left: 0px;
}
.fade {
  animation: cross 0.2s both;
}
.shake {
  animation: shake 0.4s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes cross {
  0% {
    transform: scale(1.4);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
