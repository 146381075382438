.colorPicker > div:nth-child(2) {
  position: absolute !important;
}
.chrome-picker {
  background-color: #2f2f2b !important;
  font-family: Arial !important;
}
.chrome-picker svg {
  background-color: #52524b !important;
}
.image-pointer-marker {
    font-family: 'Roboto', sans-serif;
    font-size: 1.75em;
    font-weight: bolder;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-47px);
    pointer-events: none;
}
.image-pointer-marker > div:nth-child(1) {
    background-color: #222;
    border-radius: 10px;
    padding: 2px 8px;
    opacity: .6;
    width: 100%;
    text-align: center;
}
.image-pointer-marker > div:nth-child(2) {
    width: 5px;
    height: 5px;
    border-radius: 500px;
    align-self: center;
    margin-top: 5px;
}
.points-wrapper {
  justify-content: center;
  width: fit-content;
  margin: auto;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 50px);
  grid-template-rows: repeat(2, 50px);
  grid-auto-flow: row;
}
.points {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 0;
  width: 100%;
  height: 100%;
}
.points-value {
  z-index: 0;
  font-weight: bolder !important;
  text-shadow: 0px 0px 10px #fff;
}
.pointstowin-wrapper {
  width: 100%;
}
.scoreboard-wrapper {
  justify-content: center;
  width: fit-content;
}
.box {
  background-color: #2f2f2b;
  color: #fff;
  border-radius: 4px;
  padding: 11px;
  font-size: 150%;
  position: relative;
  text-align: center;
}
.intern {
  background-color: #484845;
}
.miniName {
  font-size: 8px !important;
  text-align: center !important;
  width: 100% !important;
  position: absolute !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.pixelmatrix {
  z-index: 2;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url('./SDSOverlay/dot-mask.svg');
}
