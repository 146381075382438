// Carousel configuration parameters
$item-width: 350px; // Now we can use percentages!
$item-separation: 0px; // This now is set with Js
$viewer-distance: 400px;

.carousel {
  padding: 20px;
  height: 100vh;
  perspective: $viewer-distance;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    flex: 0 0 auto;
  }

  figure {
    margin: 0;
    width: $item-width;
    transform-style: preserve-3d;
    #transition: all 0.5s cubic-bezier(.28,.02,.68,1);

    div.carousel-item {
      background-color: #ffffff;
      color: #000000;
      display: grid;
      justify-items: center;
      align-items: center;
      width: 100%;
      height: 100px;
      border: 1px solid black;
      font-size: 1.5rem;
      box-sizing: border-box;
      padding: 0 $item-separation / 2;

      &:not(:first-of-type) {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  nav {
    position: absolute;
    top: 50px;
    display: flex;
    justify-content: center;
    margin: 20px 0 0;

    button {
      flex: 0 0 auto;
      margin: 0 5px;

      cursor: pointer;

      color: #333;
      background: none;
      border: 1px solid;
      letter-spacing: 1px;
      padding: 5px 10px;
    }
  }

}
