.App {
  height: 1080px;
  width: 1920px;
  background-color: transparent;
}
.content-wrapper {
  z-index: 0;
  height: 100%;
  width: 100%;
  background-color: green;
}
.upper-beam {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 100%;
  background-color: black;
}
.lower-beam {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 150px;
  width: 100%;
  background-color: black;
}
.upper-alert-content-wrapper {
  height: 100%;
  width: 100%;
  background-color: #ff6a00;
}
.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animate-in {
  animation-name: fadeInLeft;
}
.animate-out {
  animation-name: fadeOutRight;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
